<template>
	<div>
		<page-service-edit :is-shop="1"></page-service-edit>
	</div>
</template>

<script>
	import pageServiceEdit from '@/components/layout/land/page-service-edit.vue'
	export default{
		components:{
			pageServiceEdit
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style>
</style>
